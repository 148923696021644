<template>
  <div v-show="isShow" class="fixed z-40 inset-0 overflow-y-auto">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div
        class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div>
          <picture>
            <source
              media="(min-width:768px)"
              :srcset="`${sourceUrl}?x-oss-process=image/format,webp`"
              type="image/webp"
            />
            <img
              @load="isShow = true"
              class="rounded-xl w-full"
              :src="`${sourceUrl}?x-oss-process=image/format,webp`"
              type="image/webp"
            />
          </picture>
        </div>
        <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <button
            @click.prevent="handleOk"
            type="button"
            class="primary w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:col-start-2 sm:text-sm"
          >
            {{ okText }}
          </button>
          <button
            @click.prevent="handleClose"
            type="button"
            class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:col-start-1 sm:text-sm"
          >
            {{ cancelText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopupBanner",
  data() {
    return {
      isShow: false
    };
  },
  props: {
    sourceUrl: { type: String, default: "My Cart" },
    okText: { type: String, default: "OK" },
    cancelText: { type: String, default: "Cancel" }
  },
  methods: {
    handleClose() {
      this.$emit("onClose");
    },
    handleOk() {
      this.$emit("onOk");
    }
  }
};
</script>
