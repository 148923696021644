import store from "@/store";
import request from "@/utils/request";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import { IVoucher } from "@/store/models/voucher";
import cookies from "@/utils/cookies";

@Module({ store, dynamic: true, name: "voucherGroupStore" })
class VoucherGroupStore extends VuexModule {
  @Action
  public async fetchVoucherGroup(id: string) {
    try {
      const response: any = await request.get(`v3/vouchers/${id}`);
      const { item } = response as {
        item: any;
      };

      return item;
    } catch (e) {
      console.error(e);
    }
  }

  @Action
  async fetchUserVouchers(payload) {
    try {
      payload.orderFoods = payload.cart?.map((each) => {
        return {
          id: each.item.id,
          orderQuantity: each.itemQuantity
        };
      });

      delete payload.cart;

      const response: any = await request.post("v4/web/vouchers", payload);

      const { items } = response as {
        items: any;
      };

      return items;
    } catch (e) {
      console.error(e);
    }
  }
}
export const voucherGroupStore = getModule(VoucherGroupStore);
