
import { Component, Vue, Watch } from "vue-property-decorator";
import _ from "lodash";

import ICookie from "@/store/models/cookie";
import { userStore } from "@/store/modules/user";
import { merchantStore } from "@/store/modules/merchant";
import { cartStore } from "@/store/modules/cart";
import { trxStore } from "@/store/modules/transaction";
import { foodStore } from "@/store/modules/food";
import { voucherGroupStore } from "@/store/modules/voucherGroup";
import { menuStore } from "@/store/modules/menu";
import { tableReservationStore } from "@/store/modules/tableReservation";
import ICart, { SelectedSide, IUnavailableItemIDs } from "@/store/models/cart";
import IStore, {
  IDeliveryFare,
  IMiscellaneousFee,
  IStoreDetail
} from "@/store/models/store";
import { IPromo } from "@/store/models/promo";
import { IVoucherGroup, IVoucher } from "@/store/models/voucher";

import { Picture, Toggle, FutureBookingModal, Modal } from "@/components";
import { SwipeList, SwipeOut } from "vue-swipe-actions";
import Driver from "driver.js";
import "@/assets/driver.scss";

import { Subscription, defer } from "rxjs";
import { retryWhen, delay, take } from "rxjs/operators";

import dayjs, { Dayjs, locale } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import weekday from "dayjs/plugin/weekday";
import calendar from "dayjs/plugin/calendar";
import duration from "dayjs/plugin/duration";
import "dayjs/locale/zh-cn";
import utc from "dayjs/plugin/utc";
import empty from "is-empty";
// import Cookies from "js-cookie";
import cookies from "@/utils/cookies";
import store from "@/store";
import { uiStore } from "@/store/modules/ui";
import { isBoost, isNestle, isProduction } from "@/constant/platform";
import { tapauMerchants } from "@/constant/tapauMerchants";
import {
  mealPlanVoucherGroupIDSandbox,
  mealPlanVoucherGroupID
} from "@/constant/voucherCodes";
// import { VoucherIcon } from "@/components/General/Icons.vue";
import FoodItem from "@/components/Food/FoodItem.vue";
import PopupBanner from "@/components/General/PopupBanner.vue";
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import { Swiper as SwiperClass, Mousewheel } from "swiper/js/swiper.esm";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import "swiper/swiper.scss";
import { debounce } from "lodash";
import applyPercentageDiscount from "@/utils/discount";
import PopupMessage from "@/components/General/PopupMessage.vue";

// import 'swiper/swiper-bundle.min.css'
SwiperClass.use([Mousewheel]);
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);

@Component({
  components: {
    SwipeOut,
    SwipeList,
    Picture,
    Toggle,
    FoodItem,
    VueBottomSheet,
    Swiper,
    SwiperSlide,
    PopupBanner,
    FutureBookingModal,
    Modal,
    PopupMessage
  }
})
export default class CheckoutView extends Vue {
  dineTypes: Array<{}> = [];
  timeFormat = "HH:mm";
  selectedDineType = "";
  isShowTapauBanner = false;
  isShowShop5Banner = false;
  isShowBuyNestleProductsBanner = false;
  isShowSpinAndWinBanner = false;
  isUnsupportedDelivery = false;
  isCalculatingDeliveryFee = false;
  deliveryFeeRequestBody = {};
  deliveryAmount = -1;
  deliveryCoverFee = 0;
  deliveryFares: Array<IDeliveryFare> = [];
  deliveryDiscountAmount = -1;
  deliveryDiscountMinSpentAmount = -1;
  campaignDiscountAmount = 0;
  selectedDeliveryTime = "";
  deliveryMethods: Array<string> = [];
  specialFeeVendor = "";
  preOrderVendor = "";
  changingTime = false;
  changingPickupOption = false;
  changingDriveThruOption = false;
  changingDeliveryOption = false;
  changingPromoCode = false;
  // changingTableReservation = false;
  unsavedOrderDayIndex = 0;
  unsavedDeliveryTimeIndex = 0;
  unsavedReserveTimeIndex = 0;
  unsavedDeliveryTime = "";
  dineInOrReserve = "";
  showBookingModal = false;
  tncVoucherGroup: IVoucherGroup | null = null;
  hasShownBookingModal = false;

  selectedReserveDayIndex: any = 0;
  selectedReserveDeliveryTime: string = "";
  reservePax = 1;
  isReserveLoading = false;
  reserveRemark = "";
  resetIndex = false;

  vehicleNoInput = "";
  vehicleDescInput = "";
  tableNoInput = "";
  promoCodeInput = "";
  appliedPromoCode = "";
  appliedStaticPromoCodeForDisplay = "";
  isInactive = false;

  promo: IPromo | null = null;

  todayStartAt: Dayjs = dayjs();
  todayEndAt: Dayjs = dayjs();
  now: Dayjs = dayjs();

  isPaying = false;
  driver: Driver | null = null;

  deliveryOptions = {
    MOTORBIKE: "Motorcycle",
    CAR: "Car"
  };
  selectedDeliveryOption = "MOTORBIKE";
  selectedOrderDayIndex = 0;
  selectedPickupOptionIndex = 0;
  selectedDriveThruOptionIndex = 0;
  selectedPickupOption = "";
  selectedDriveThruOption = "";
  isPromptMotorcycleModal = false;
  isProceedWithMotorcycle = false;
  isCutleryRequired = false;
  isExceedBikeSize = false;

  nestleProducts: any[] = [];

  updateNowTimer;
  durationTimeout;

  swiperOptions = {
    direction: "vertical",
    slidesPerView: 8,
    spaceBetween: 4,
    centeredSlides: true,
    mousewheel: {
      releaseOnEdges: true
      // sensitivity: 2
    },
    grabCursor: true,
    touchReleaseOnEdges: false
  };

  localCart: ICart[] = [];

  deliveryLocation = {} as any;

  // Vouchers
  isVoucherLoading = false;
  localVouchers: IVoucher[] = [];
  isVoucherApplied = false;
  voucherGroupCodeApplied = "";

  availability = {} as any;

  private subscription?: Subscription;

  isShowPopUpMessage = false;

  get campaigns() {
    return menuStore.campaigns;
  }

  //TODO: make cart backend based and check availability together
  //TODO: group cart items by lead time within the same day

  get mealPlanItem() {
    return (cartStore.cart?.[0] as ICart) || null;
  }

  get isDisplayingDatePicker() {
    return (
      !this.isStoreClosed &&
      ((this.isNestle &&
        this.preparationBufferTime >= 1440 &&
        this.firstTimeSlot) ||
        this.isOrderPossible)
    );
  }
  get isMealPlan() {
    return this.mealPlanItem?.selectedDineType == "MEAL_PLAN";
  }

  get mealPlan() {
    return this.mealPlanItem?.mealPlan || null;
  }
  get isDeliveryCovered() {
    return this.mealPlan?.isDeliveryCovered;
  }
  get isShowDatesCheckout() {
    return this.mealPlan?.isShowDatesCheckout;
  }

  get windowTitle() {
    return (
      merchantStore.currentStore?.name +
      " - " +
      merchantStore.merchant?.company.name
    );
  }

  get currentStore() {
    return merchantStore.currentStore;
  }

  get totalItems() {
    let total = this.localCart.reduce(function(total, item) {
      return total + item.itemQuantity;
    }, 0);
    return total;
  }
  get cartStoreTotal() {
    return cartStore.totalItems;
  }
  get userStore() {
    return userStore;
  }
  get user() {
    return userStore.user;
  }
  get profile() {
    return userStore.user.profile;
  }
  get cart() {
    return cartStore.cart;
  }
  get table() {
    return merchantStore.table;
  }
  get tableNamePlaceholder() {
    return this.table.isDefault ? "" : this.table.name;
  }
  get hasCustomerEntry() {
    return merchantStore.hasCustomerEntry;
  }
  get orderAheadDays(): number {
    return merchantStore.currentStore?.orderAheadDays || 0;
  }
  get preparationBufferTime(): number {
    if (merchantStore.currentStore?.preparationBufferTime === 0) return 90;
    else return merchantStore.currentStore?.preparationBufferTime || 90;
  }
  get deliveryTime() {
    if (!merchantStore.currentStore) return [];
    return merchantStore.currentStore.deliveryTime.filter(
      (time) => time.length > 0
    );
  }
  get isStoreUsingOwnDelivery() {
    return (
      merchantStore.currentStore &&
      merchantStore.currentStore.deliveryMethods?.length > 0 &&
      merchantStore.currentStore.deliveryMethods.includes("OWN")
    );
  }
  // get deliveryTimeForDelivery() {
  //   if (!merchantStore.currentStore) return [];
  //   return merchantStore.currentStore.deliveryTime
  //     .map((time) =>
  //       time.filter((currentTime) => {
  //         // TODO: The following disables peak hour deliveyr time
  //         if (this.isBoost || this.isStoreUsingOwnDelivery) {
  //           return true;
  //         }
  //         const deliveryTime = dayjs(currentTime);
  //         const deliveryHour = deliveryTime.hour();

  //         return !(deliveryHour >= 11 && deliveryHour < 14) && !(deliveryHour >= 17 && deliveryHour < 20);
  //       })
  //     )
  //     .filter((time) => time.length > 0);
  // }

  get reservationTime() {
    return (
      merchantStore.currentStore?.tableReservationTime?.filter(
        (time) => time.length > 0
      ) || []
    );
  }

  get firstTimeSlot() {
    for (let i = 0; i < this.deliveryTime.length; i++) {
      if (this.deliveryTime[i].length > 0) {
        return dayjs(this.deliveryTime[i][0]);
      }
    }

    return null;
  }

  get hasDeliveryAddress() {
    return this.deliveryLocation?.lat;
    // return (
    //   this.user.profile &&
    //   this.user.profile?.addresses &&
    //   this.user.profile?.addresses.length > 0 &&
    //   this.user.profile?.primaryAddress > -1
    // );
  }

  get isOpen(): boolean {
    return merchantStore.currentStore?.isOpen || false;
  }

  get nextOpeningHour(): string {
    return merchantStore.currentStore?.nextOpeningHour || "";
  }

  get previousClosingHour(): string {
    return merchantStore.currentStore?.previousClosingHour
      ? dayjs(merchantStore.currentStore?.previousClosingHour).format("HH:mm")
      : "";
  }

  get currentOpeningHour(): string {
    return merchantStore.currentStore?.currentOpeningHour
      ? dayjs(merchantStore.currentStore?.currentOpeningHour).format("HH:mm")
      : "";
  }

  get currentClosingHour(): string {
    return merchantStore.currentStore?.currentClosingHour
      ? dayjs(merchantStore.currentStore?.currentClosingHour).format("HH:mm")
      : "";
  }

  get yesterday() {
    return dayjs().subtract(1, "day");
  }

  mealPlanDateDeliverAt(date: string) {
    return dayjs(date).format("YYYY-MM-DD");
  }
  get isPreOrderAvailable() {
    return this.orderAheadDays > 0 && this.hasDeliveryTimeSlot;
  }
  public isRecursiveArrayEmpty(array) {
    return (
      Array.isArray(array) &&
      (array.length == 0 || array.every(this.isRecursiveArrayEmpty))
    );
  }
  get hasDeliveryTimeSlot() {
    if (this.dineInOrReserve == "RESERVATION") {
      return !this.isRecursiveArrayEmpty(this.reservationTime);
    }
    return !this.isRecursiveArrayEmpty(this.deliveryTime);
  }
  get preOrderDays() {
    if (merchantStore.currentStore) {
      const { deliveryTime = [] } = merchantStore.currentStore as {
        deliveryTime: string[][];
      };
      let filteredDeliveryTime = deliveryTime.map((each, index) => {
        return this.now.add(index, "day");
      });
      return filteredDeliveryTime.filter(
        (time, i) => deliveryTime[i].length > 0
      );
      // return deliveryTime.map((each, index) => {
      //   return this.now.add(index, "day");
      // });
    }
    return [];
  }

  get reservePreOrderDays() {
    if (merchantStore.currentStore) {
      const { tableReservationTime = [] } = merchantStore.currentStore as {
        tableReservationTime: string[][];
      };
      let filteredDeliveryTime = tableReservationTime.map((each, index) => {
        return this.now.add(index, "day");
      });
      return filteredDeliveryTime.filter(
        (time, i) => tableReservationTime[i].length > 0
      );
    }
    return [];
  }

  get isPaymentInProcess() {
    const isLoading = this.isPaying;
    return isLoading;
  }

  get isInKlangValley() {
    return (
      this.currentStore?.state.includes("Selangor") ||
      this.currentStore?.state.includes("Kuala Lumpur") ||
      this.currentStore?.city.includes("Kuala Lumpur")
    );
  }

  get isShowWhenOption() {
    // if (this.selectedDineType !== "DINEIN") return true;
    // return (
    //   this.currentStore &&
    //   (!this.currentStore.isAllowReserveTable || // no reserve table option
    //     this.currentStore.tableReservationDuration == 0 || // no reserve table option
    //     !this.reservedTable) // not using reserve table function
    // );
    if (this.userStore.isFromQR) return false;
    return true;
  }

  get isShowDeliveryOption() {
    return (
      !this.isMealPlan &&
      this.selectedDineType === "DELIVERY" &&
      // Only in klang valley
      this.isInKlangValley &&
      this.currentStore &&
      Array.isArray(this.currentStore.deliveryTypes) &&
      this.currentStore.deliveryTypes.length > 1 // Only show options if there are options to be selected
    );
  }

  get isShowTableNumberOption() {
    // if (this.selectedDineType !== "DINEIN") return true;
    if (this.dineInOrReserve == "DINEIN") {
      return true;
    }
    return (
      this.currentStore &&
      (!this.currentStore.isAllowReserveTable || // no reserve table option
      this.currentStore.tableReservationDuration == 0 || // no reserve table option
        !this.reservedTable) // not using reserve table function
    );
  }

  get isShowTableReservationOption() {
    if (this.selectedDineType !== "DINEIN") return false;
    return (
      this.currentStore &&
      this.currentStore.isAllowReserveTable &&
      this.currentStore.tableReservationDuration > 0 &&
      this.reservedTable // using reserve table function
    );
  }

  get excludedDeliveryOptions() {
    const otherDeliveryOptionsExceptBike =
      this.currentStore?.deliveryTypes
        ?.map((item) => item?.type)
        .filter((el) => el != "MOTORBIKE") ?? [];
    return otherDeliveryOptionsExceptBike.length > 0 && this.isExceedBikeSize
      ? ["MOTORBIKE"]
      : [];
  }

  get storeDeliveryOptions() {
    let storeDeliveryOptions =
      this.currentStore?.deliveryTypes &&
      this.currentStore.deliveryTypes.length > 0
        ? this.currentStore?.deliveryTypes?.map((item) => item?.type)
        : ["CAR"];

    storeDeliveryOptions = storeDeliveryOptions.filter(
      (el) => !this.excludedDeliveryOptions.includes(el)
    );
    return storeDeliveryOptions;
  }

  get isPayNowDisabled() {
    let disabled = false;

    if (this.selectedDineType === "DRIVE_THRU" && !this.vehicleNoInput) {
      return false;
    } else if (
      this.selectedDineType === "DELIVERY" &&
      !this.hasDeliveryAddress
    ) {
      return false;
    } else if (userStore.isLoggedIn) {
      disabled =
        !this.selectedDeliveryTime ||
        this.subtotal < 5 ||
        this.isPaymentInProcess ||
        (this.isAlipay && this.subtotal < 10) ||
        (this.selectedDineType === "DELIVERY" &&
          this.isCalculatingDeliveryFee) ||
        (this.selectedDineType === "DELIVERY" &&
          (this.subtotal < 500 ||
            (this.deliveryFee === -1 && !this.isUnsupportedDelivery))) ||
        (this.selectedDineType === "DELIVERY" &&
          this.totalAfterRounding < this.deliveryFee) ||
        (this.promoDiscountAmount > 0 && this.totalAfterRounding <= 0) ||
        (!this.isPreOrderAvailable && !this.isTodayOpen) ||
        (!this.isNowWithinBusinessHour &&
          this.isTodayOpen &&
          !this.isPreOrderAvailable) ||
        this.isStoreClosed;
    }

    return disabled;
  }

  get miscFees() {
    if (merchantStore.currentStore) {
      let { miscellaneousFees, language } = merchantStore.currentStore as {
        miscellaneousFees: IMiscellaneousFee[];
        language?: IStoreDetail;
      };
      if (miscellaneousFees && language) {
        miscellaneousFees = miscellaneousFees.map((fee, i) => {
          fee.description =
            language?.[this.$i18n.locale]?.miscellaneousFees?.[i] ||
            fee.description;
          return fee;
        });
      }
      const cutleryFee = this.merchantStore.currentStore?.cutleryFee || 0;

      if (this.isCutleryRequired && cutleryFee > 0) {
        if (!miscellaneousFees) {
          miscellaneousFees = [];
        }

        miscellaneousFees.push({
          description: "Cutlery",
          amount: cutleryFee
        } as IMiscellaneousFee);
      }
      return miscellaneousFees ? miscellaneousFees : [];
    }
    return [];
  }

  get totalMiscFee() {
    let total = this.miscFees.reduce(function(total, item) {
      return total + item.amount;
    }, 0);

    return total;
  }

  get serviceFee() {
    return this.calculateAmountByPercentage(
      this.getServiceFeePercentage(this.selectedDineType),
      this.subtotal
    );
  }
  get serviceTax() {
    let taxAmount = this.localCart.reduce(function(total, item) {
      return total + item.comboTaxAmount * item.itemQuantity;
    }, 0);

    taxAmount += this.localCart.length > 0 ? this.miscFeesTaxAmount : 0;

    return Math.round(taxAmount);
  }

  get deliveryFee() {
    return this.selectedDineType === "DELIVERY" &&
      (!this.isDeliveryCovered || this.deliveryAmount == -1)
      ? this.deliveryAmount
      : 0;
  }
  get deliveryDiscountFee() {
    if (this.deliveryFee <= 0 || this.isDeliveryCovered) {
      return 0;
    }
    let fee =
      this.selectedDineType === "DELIVERY"
        ? this.deliveryFee < this.deliveryDiscountAmount
          ? this.deliveryFee
          : this.deliveryDiscountAmount
        : 0;

    if (this.isMealPlan) {
      fee = fee * this.mealPlanItem.selectedSides.length;
    }

    return fee;
  }

  get deliveryDiscountMinSpentFee() {
    return this.deliveryDiscountFee > 0
      ? this.deliveryDiscountMinSpentAmount
      : 0;
  }

  get promoDiscountAmount() {
    if (this.promo) {
      switch (this.promo.type) {
        case "DISCOUNT":
          const orderAmount =
            ((this.subtotal + this.serviceFee + this.serviceTax) / 100) * 100;
          return Math.round(
            (this.promo.discountRate / 100 / 100) * orderAmount
          );
        case "CASH":
        case "PERCENTAGE": // PERCENTAGE is used for alacarte funded promo with max capped, so the amount is calculated backend
          return this.promo.amount;
      }
    }
    return 0;
  }
  get subtotal() {
    let subtotal = this.localCart.reduce(function(total, item) {
      return total + item.combinationPricePerItem * item.itemQuantity;
    }, 0);

    subtotal += this.localCart.length > 0 ? this.totalMiscFee : 0;
    return subtotal;
  }

  get miscFeesTaxAmount() {
    return (this.totalMiscFee * 600) / 10000;
  }

  get totalBeforeRoundingWithoutPromo() {
    return (
      ((this.subtotal +
        this.serviceFee +
        this.serviceTax +
        (this.deliveryFee > -1 ? this.deliveryFee : 0) -
        this.deliveryDiscountFee) /
        100) *
      100
    );
  }
  get totalBeforeRounding() {
    const total =
      this.totalBeforeRoundingWithoutPromo -
      this.promoDiscountAmount -
      this.campaignDiscountAmount;
    return total >= 0 ? total : 0;
  }
  get totalAfterRounding() {
    // return this.round(this.totalBeforeRounding, 0.05);
    return this.totalBeforeRounding;
  }
  get rounding() {
    return this.totalAfterRounding - this.totalBeforeRounding;
  }

  get isEnabledLoyalty() {
    return merchantStore.merchant?.isEnabledLoyalty;
  }

  get isStoreClosed() {
    return dayjs(merchantStore.currentStore?.closedUntil)
      .utc()
      .isAfter(this.now.utc(), "second");
  }

  get isOrderPossible() {
    return (
      this.isPreOrderAvailable ||
      (this.isNowWithinBusinessHour && this.deliveryTime?.[0]?.length > 0)
    );
  }

  get isReservationPossible() {
    return this.isPreOrderAvailable || this.isNowWithinBusinessHour;
  }

  get noReservationTimeSlot() {
    return this.reservationTime.length == 0;
  }

  get isHaveHoursFromYesterday() {
    return this.previousClosingHour != ""; // backend already calculated, if it's not empty, means yesterday hours overflowing until current moment
  }

  get isNowWithinBusinessHour() {
    return this.isOpen;
  }

  get isTodayOpen() {
    return this.currentOpeningHour != "" && this.currentClosingHour != "";
  }

  get nextWorkingDay() {
    let nextWorkingDay = { isNext: false, dayjs: dayjs() };

    if (this.nextOpeningHour != "") {
      nextWorkingDay.isNext = true;
      nextWorkingDay.dayjs = dayjs(this.nextOpeningHour);
    }

    return nextWorkingDay;
  }

  get isEnablePickupOption() {
    return merchantStore.currentStore?.isEnablePickupOption;
  }

  get pickupOptions() {
    if (merchantStore.currentStore) {
      let { pickupOptions, language } = merchantStore.currentStore as {
        pickupOptions: string[];
        language?: IStoreDetail;
      };
      if (pickupOptions && language) {
        pickupOptions = pickupOptions.map((opt, i) => {
          return language?.[this.$i18n.locale]?.pickupOptions?.[i] || opt;
        });
      }
      return pickupOptions ? pickupOptions : [];
    }
    return [];
  }

  get isEnableDriveThruOption() {
    return merchantStore.currentStore?.isEnableDriveThruOption;
  }

  get driveThruOptions() {
    if (merchantStore.currentStore) {
      let { driveThruOptions, language } = merchantStore.currentStore as {
        driveThruOptions: string[];
        language?: IStoreDetail;
      };
      if (driveThruOptions && language) {
        driveThruOptions = driveThruOptions.map((opt, i) => {
          return language?.[this.$i18n.locale]?.driveThruOptions?.[i] || opt;
        });
      }
      return driveThruOptions ? driveThruOptions : [];
    }
    return [];
  }

  get reservedTable() {
    return tableReservationStore.tableReservation;
  }

  get isTableReserved() {
    return !empty(tableReservationStore.tableReservation?.startAt);
  }

  get reservedTableText() {
    if (this.dineInOrReserve == "DINEIN") {
      return "NOW";
    }

    let startAt = dayjs(this.reservedTable?.startAt).format("D/MM, h:mm A");
    let endAt = dayjs(this.reservedTable?.endAt).format("h:mm A");
    if (this.reservedTable?.startAt || this.reservedTable?.endAt)
      return `${this.$t("Checkout.reserved", {
        pax: this.reservedTable?.noOfPax,
        datetime: `${startAt} - ${endAt}`
      })}`;
    else return "";
  }

  get merchantStore() {
    return merchantStore;
  }

  get isAlipay() {
    return merchantStore.platform == "ALIPAY";
  }

  get isMiniProgram() {
    return merchantStore.platform !== "WEB";
  }

  get isBoost() {
    // return userStore.user.boostCustomerId != "";
    return isBoost();
  }

  get isNestle() {
    return isNestle();
  }

  get isSenHeng() {
    return merchantStore.platform == "SENHENG";
  }

  get isTapauMerchant() {
    if (merchantStore.merchant?.merchantId)
      return tapauMerchants.includes(merchantStore.merchant?.merchantId);
    return false;
  }

  get isMealPlanVoucher() {
    return (
      mealPlanVoucherGroupIDSandbox.includes(this.voucherGroupCodeApplied) ||
      mealPlanVoucherGroupID.includes(this.voucherGroupCodeApplied)
    );
  }

  get displayTimeRange() {
    return (
      this.selectedDineType == "DELIVERY" ||
      ((this.selectedDineType == "PICKUP" ||
        this.selectedDineType == "DRIVE_THRU") &&
        this.preparationBufferTime > 90)
    );
  }

  get isDineFirstPayLater() {
    return (
      merchantStore?.currentStore?.isDineFirstPayLater &&
      this.selectedDineType === "DINEIN"
    );
  }

  public round(value: number, step: number): number {
    step || (step = 1.0);
    const inv = 1.0 / step;
    return (Math.round((value / 100) * inv) / inv) * 100;
  }
  public calculateAmountByPercentage(rate: number, amount: number) {
    const r = rate / 100;
    const a = amount;
    return Math.round((a * r) / 100);
  }

  public getServiceFeePercentage = (dineType: string) => {
    if (dineType === "") {
      return 0;
    } else {
      if (merchantStore.currentStore) {
        const {
          serviceFee,
          pickupServiceFee
        } = merchantStore.currentStore as any;
        switch (dineType) {
          case "PICKUP":
          case "DRIVE_THRU":
          case "DELIVERY":
            return pickupServiceFee;
          case "DINEIN":
            return serviceFee;
          default:
            return 0;
        }
      } else {
        return 0;
      }
    }
  };
  public getServiceTaxPercentage = (dineType: string) => {
    if (dineType === "") return 0;
    if (merchantStore.currentStore) {
      const {
        serviceTax,
        pickupServiceTax
      } = merchantStore.currentStore as IStore;
      switch (dineType) {
        case "PICKUP":
        case "DRIVE_THRU":
        case "DELIVERY":
          return pickupServiceTax;
        case "DINEIN":
          return serviceTax;
        default:
          return 0;
      }
    } else {
      return 0;
    }
  };

  public calculateDeliveryFee() {
    this.startCalculateDeliveryFee();
  }

  public CDF() {
    if (userStore.isLoggedIn && this.deliveryLocation?.lat) {
      const oldBody = this.deliveryFeeRequestBody;

      this.deliveryFeeRequestBody = {
        address: this.deliveryLocation,
        orderAmount: this.subtotal,
        deliveryType: this.isMealPlan
          ? "MOTORBIKE"
          : this.selectedDeliveryOption,
        deliveryTime: this.isMealPlan
          ? this.dummyZeekDeliveryTime
          : this.getDeliveryTime(
              this.preOrderDays[this.selectedOrderDayIndex],
              this.selectedDeliveryTime
            ).toISOString(),
        isMealPlan: this.isMealPlan,
        dineTime: this.mealPlanItem?.selectedDineTime
      };

      if (
        JSON.stringify(oldBody) == JSON.stringify(this.deliveryFeeRequestBody)
      )
        return;

      this.deliveryAmount = -1;
      this.deliveryDiscountAmount = -1;
      this.deliveryDiscountMinSpentAmount = -1;

      this.subscription?.unsubscribe(); // when delivery type is excluded due to size limitation, we need to cancel previous quotation request

      this.isCalculatingDeliveryFee = true;

      const calculateDeliveryFee$ = defer(() =>
        cartStore.calculateDeliveryFees(this.deliveryFeeRequestBody)
      ).pipe(
        retryWhen((errors) => {
          this.isCalculatingDeliveryFee = false;

          //disabling the fee calculation retry for now.

          // this.$ionic.toastController
          //   .create({
          //     header: "Retrying",
          //     position: "top",
          //     color: "primary",
          //     duration: 2000,
          //     buttons: [
          //       {
          //         icon: "close",
          //         role: "cancel"
          //       }
          //     ]
          //   })
          //   .then((x) => x.present());

          return errors.pipe(delay(2000), take(0));
        })
      );
      this.subscription = calculateDeliveryFee$.subscribe((item) => {
        this.isCalculatingDeliveryFee = false;
        if (item) {
          this.isUnsupportedDelivery = false;

          const {
            amount,
            deliveryCoverFee,
            discountAmount,
            minSpentAmount,
            deliveryMethods,
            deliveryFares,
            specialFeeVendor,
            preOrderVendor
          } = item as {
            amount: number;
            deliveryCoverFee: number;
            discountAmount: number;
            minSpentAmount: number;
            deliveryMethods: Array<string>;
            deliveryFares: Array<IDeliveryFare>;
            specialFeeVendor: string;
            preOrderVendor: string;
          };
          this.deliveryAmount = this.isMealPlan
            ? amount * this.mealPlanItem.selectedSides.length
            : amount;
          this.deliveryCoverFee = deliveryCoverFee;
          this.deliveryDiscountAmount = discountAmount;
          this.deliveryDiscountMinSpentAmount = minSpentAmount;
          this.deliveryMethods = deliveryMethods;
          this.specialFeeVendor = specialFeeVendor;
          this.deliveryFares = deliveryFares;
          this.preOrderVendor = preOrderVendor;

          if (
            this.isMealPlan &&
            this.mealPlan.isDeliveryCovered &&
            this.deliveryAmount > this.mealPlan.maxDeliveryFee.amount
          ) {
            this.isUnsupportedDelivery = true;
            this.$ionic.toastController
              .create({
                header: "Out of delivery range",
                position: "top",
                color: "primary",
                duration: 3500,
                buttons: [
                  {
                    icon: "close",
                    role: "cancel"
                  }
                ]
              })
              .then((x) => x.present());
          }
        } else if (item == null) {
          this.isUnsupportedDelivery = true;
        }
      });
    }
  }

  public debouncedApplyPromo = debounce(this.applyPromoCode, 300);
  public startCalculateDeliveryFee = debounce(this.CDF, 1000);

  public countLabelsQty(labels) {
    return labels.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id);
      if (!x) {
        return acc.concat([{ ...current, count: 1 }]);
      } else {
        x.count++;
        return acc;
      }
    }, []);
  }
  public countItemsQty(items) {
    return items.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id);
      if (!x) {
        current.count = 1;
        return acc.concat([{ ...current, count: 1 }]);
      } else {
        x.count++;
        return acc;
      }
    }, []);
  }

  checkIsEntitledForSpinAndWin(isCheckCartOnly: boolean) {
    return false;
    // // Check if spin and win banner has been popup before.
    // // If yes, then not entitled for spin and win
    // if (!isCheckCartOnly && this.currentStore) {
    //   const spinAndWinValue = cookies.get(`spin-and-win-${this.currentStore.id}`);
    //   if (spinAndWinValue) {
    //     const spinAndWin: ICookie = JSON.parse(spinAndWinValue);
    //     if (spinAndWin.expiry && dayjs().isBefore(dayjs(spinAndWin.expiry))) {
    //       return false;
    //     }
    //   }
    // }
    // if (this.nestleProducts.length == 0) return false;
    // for (let i = 0; i < this.nestleProducts.length; i++) {
    //   const currentNestleProduct = this.nestleProducts[i];
    //   let cartItem = this.getItemFromCart(currentNestleProduct.id);

    //   if (cartItem) return true;
    // }
    // return false;
  }

  applyShop5(isApply) {
    cookies.set(`isShownShop5Banner`, true);
    this.isShowShop5Banner = false;
    if (isApply) this.reapplyPromoCode("SHOP5");
  }

  applyTapau(isApply) {
    cookies.set(`isShownTapauBanner`, true);
    this.isShowTapauBanner = false;
    if (isApply) {
      this.onSelectDineType("PICKUP");
      this.reapplyPromoCode("TAPAULAH");
    }
  }

  reapplyPromoCode(code, isAutoApply = false) {
    this.promoCodeInput = code;
    this.debouncedApplyPromo(isAutoApply);
  }
  get promoRedeemAmount() {
    return this.subtotal + this.serviceFee + this.serviceTax;
  }
  async applyPromoCode(isAutoApply = false) {
    if (this.promoRedeemAmount == 0) {
      return;
    }

    try {
      if (merchantStore.currentStore && this.promoCodeInput.trim().length > 0) {
        const payload = {
          code: this.promoCodeInput.trim(),
          amount: this.promoRedeemAmount,
          deliveryFee:
            this.deliveryFee > 0
              ? this.deliveryFee - this.deliveryDiscountFee
              : 0,
          isCheckLoyalty: this.isEnabledLoyalty,
          dineType: this.selectedDineType,
          cart: this.$store.state.cartStore.cart
        };
        // appliedStaticPromoCodeForDisplay
        const promo = await cartStore.applyPromoCode(payload);

        if (promo?.code) {
          this.promo = promo;
          this.appliedPromoCode = this.promoCodeInput;
          // Set the promo code into cookie
          cookies.set(
            `promo-${await cookies.get("currentStore")}`,
            this.appliedPromoCode
          );

          if (this.isMealPlanVoucher) {
            this.isVoucherApplied = true;
          }
          if (isAutoApply) {
            this.$ionic.toastController
              .create({
                header: "Voucher auto applied",
                position: "top",
                color: "success",
                duration: 1500,
                buttons: [
                  {
                    icon: "close",
                    role: "cancel"
                  }
                ]
              })
              .then((x) => x.present());
          }
        } else {
          this.removePromoCode();
        }
      }
    } catch (e) {
      this.removePromoCode();
      console.log("Error", e);
    }

    this.changingPromoCode = false;
  }

  async removePromoCode() {
    this.promoCodeInput = "";
    this.appliedPromoCode = "";
    this.appliedStaticPromoCodeForDisplay = "";
    this.promo = null;
    this.changingPromoCode = false;
    cookies.remove(`promo-${await cookies.get("currentStore")}`);
    cookies.remove(`promo-display-${await cookies.get("currentStore")}`);
    this.voucherGroupCodeApplied = "";
    this.isVoucherApplied = false;
  }

  async fUv(isApply = false) {
    if (!userStore.isLoggedIn || this.promoRedeemAmount == 0) {
      return;
    }
    const items = (await voucherGroupStore.fetchUserVouchers({
      userId: userStore.profile?.id || "",
      storeID: this.currentStore?.id || "",
      amount: this.promoRedeemAmount,
      deliveryFee:
        this.deliveryFee > 0 ? this.deliveryFee - this.deliveryDiscountFee : 0,
      dineType: this.selectedDineType,
      cart: this.$store.state.cartStore.cart
    })) as IVoucher[];
    this.localVouchers = items;
    let sortingVouchers = [...items];
    sortingVouchers = sortingVouchers.sort((a, b) => {
      const p1 = this.getPromoPrice(a, this.promoRedeemAmount);
      const p2 = this.getPromoPrice(b, this.promoRedeemAmount);
      if (p1 > p2) return 1;
      if (p2 > p1) return -1;
      return 0;
    });
    if (isApply) {
      for (let i = 0; i < sortingVouchers?.length; i++) {
        const currentVoucher = sortingVouchers[i];

        if (currentVoucher.isDisabled) {
          continue;
        }
        this.setPromoDisplay(currentVoucher);
        this.reapplyPromoCode(currentVoucher.code, true);
        break;
      }
    }
  }

  public fetchUserVouchers = debounce(this.fUv, 500);

  get getPromoPrice() {
    return (v: IVoucher | null, price: number) => {
      if (v?.group.type == "CASH") {
        return Math.max(0, price - (v?.amount || 0));
      } else if (v?.group.type == "PERCENTAGE") {
        return applyPercentageDiscount(price, v?.discountRate || 0);
      }
      return -1;
    };
  }

  proceedWithMotorcycle() {
    this.isPromptMotorcycleModal = false;
    this.isProceedWithMotorcycle = true;
    this.onCreateOrder();
  }

  async setCheckoutDetail() {
    this.selectedDeliveryTime =
      (await cookies.get("selectedDeliveryTime")) || "";
    this.selectedOrderDayIndex = parseInt(
      (await cookies.get("selectedOrderDayIndex")) ?? "0"
    );
  }

  removeTableReservation() {
    tableReservationStore.removeTableReservation(this.currentStore?.id || "");
  }

  get tableReservationID() {
    if (this.dineInOrReserve == "RESERVATION" && this.reservedTable) {
      return this.reservedTable.id;
    }
    return "";
  }

  get dummyZeekDeliveryTime() {
    return dayjs("15:30", "HH:mm") // order from zeek for next day offpeak hours
      .add(1, "day")
      .utc()
      .toISOString();
  }

  async onCreateOrder() {
    if (!userStore.isLoggedIn && this.selectedDineType !== "DINEIN") {
      this.$router.push({ name: "Login", query: { redirect: "Checkout" } });
    } else if (
      !userStore.isProfileCompleted &&
      this.selectedDineType !== "DINEIN"
    ) {
      this.$router.push({ name: "Profile" });
    } else if (
      this.selectedDineType === "DELIVERY" &&
      (this.isUnsupportedDelivery || !this.hasDeliveryAddress)
    ) {
      this.$router.push({ name: "AddressSelect" });
    } else if (this.selectedDineType === "DRIVE_THRU" && !this.vehicleNoInput) {
      this.$router.push({ name: "Vehicle" });
    } else if (this.isPaying == false) {
      // Dine in without table reservation
      if (
        this.selectedDineType == "DINEIN" &&
        this.table.isDefault &&
        !this.tableNoInput &&
        !this.reservedTable
      ) {
        this.$ionic.toastController
          .create({
            header: "Please key in your table no.",
            position: "top",
            color: "danger",
            duration: 2000,
            buttons: [
              {
                icon: "close",
                role: "cancel"
              }
            ]
          })
          .then((x) => x.present());
        return;
      }

      let deliveryTime;
      if (this.dineInOrReserve == "RESERVATION") {
        deliveryTime = this.reservedTable?.startAt;
      } else if (this.dineInOrReserve == "DINEIN") {
        deliveryTime = "ASAP";
      } else {
        deliveryTime = this.isMealPlan
          ? this.dummyZeekDeliveryTime
          : this.getDeliveryTime(
              this.preOrderDays[this.selectedOrderDayIndex],
              this.selectedDeliveryTime
            ).toISOString();
      }

      if (this.selectedDeliveryTime != "ASAP") {
        if (
          dayjs(deliveryTime)
            .utc()
            .isBefore(
              dayjs()
                .utc()
                .add(10, "minute")
            )
        ) {
          setTimeout(() => {
            this.$router.go(0);
          }, 2500);
          this.$ionic.toastController
            .create({
              header: "Timeslot not available anymore, please select again",
              position: "top",
              color: "danger",
              duration: 5000,
              buttons: [
                {
                  icon: "close",
                  role: "cancel"
                }
              ]
            })
            .then((x) => x.present());
          return;
        }
        // if (
        //   !this.hasShownBookingModal &&
        //   dayjs(deliveryTime)
        //     .utc()
        //     .isAfter(
        //       dayjs()
        //         .utc()
        //         .add(3, "h")
        //     )
        // ) {
        //   this.showBookingModal = true;
        //   this.hasShownBookingModal = true;
        //   return;
        // }
      }

      // Check the number of items to prompt delivery dialog
      if (
        cartStore.totalItems > 3 &&
        this.isShowDeliveryOption &&
        this.selectedDineType == "DELIVERY" &&
        !this.isProceedWithMotorcycle &&
        this.selectedDeliveryOption == "MOTORBIKE"
      ) {
        this.isPromptMotorcycleModal = true;
        return;
      }

      // if (!this.isValidDeliveryTime(this.selectedDeliveryTime)) {
      //   this.$ionic.toastController
      //     .create({
      //       header: "Ops! Riders may not be available during peak hours, please select different timeslot",
      //       position: "top",
      //       color: "danger",
      //       duration: 2000,
      //       buttons: [
      //         {
      //           icon: "close",
      //           role: "cancel"
      //         }
      //       ]
      //     })
      //     .then((x) => x.present());
      //   return;
      // }

      this.isPaying = true;

      uiStore.setLoader(true);

      const order = await cartStore.createOrder({
        dineType: this.selectedDineType,
        cart: this.$store.state.cartStore.cart,
        deliveryTime: this.isMealPlan
          ? this.dummyZeekDeliveryTime
          : deliveryTime,
        deliveryType:
          this.selectedDineType == "DELIVERY"
            ? this.isMealPlan
              ? "MOTORBIKE"
              : this.selectedDeliveryOption
            : "",
        vehicleNo: this.vehicleNoInput,
        vehicleDescription: this.vehicleDescInput,
        driveThruOption: this.selectedDriveThruOption,
        pickupOption: this.selectedPickupOption,
        // deliveryAddressId: this.user.profile?.primaryAddress || 0,
        address: this.deliveryLocation,
        tableNo: this.tableNoInput,
        tableReservationID: this.tableReservationID,
        promoCode: this.promo?.code || "",
        isCutleryRequired: this.isCutleryRequired,
        deliveryMethods: this.deliveryMethods,
        deliveryFares: this.deliveryFares,
        specialFeeVendor: this.specialFeeVendor,
        preOrderVendor: this.preOrderVendor,
        quotedDeliveryFee: this.isMealPlan
          ? this.deliveryAmount
          : this.deliveryFee,
        dineTime: this.mealPlanItem?.selectedDineTime
      });

      if (order) {
        if (this.isDineFirstPayLater) {
          uiStore.setLoader(false);
          this.clearCart();
          trxStore.clearOrderHistory();
          this.$router.replace({
            name: "OrderSummary",
            query: { orderID: order.id }
          });
        } else {
          await this.onCreateTransaction("PAYORDER", "", order!.storeID, [
            order!.id
          ]);
        }
      } else {
        uiStore.setLoader(false);
      }
      this.tableNoInput = "";
      this.isPaying = false;
    }
  }
  async onCreateTransaction(
    paymentType = "PAYORDER",
    paymentMethod = "",
    storeID = "",
    orderIDs: string[]
  ) {
    const isAlipay = merchantStore.platform == "ALIPAY";
    const isTNG = merchantStore.platform == "TNG";

    const transaction = await trxStore.createTransaction({
      paymentType,
      paymentMethod,
      storeID,
      orderIDs,
      isAlipay
    });
    const { paymentRedirectUrl, transactionData } = transaction as {
      paymentRedirectUrl: string;
      transactionData: string;
    };
    this.clearCart();
    trxStore.clearOrderHistory();
    if (transactionData != "") {
      uiStore.setLoader(false);
      if (isAlipay || isTNG) {
        const result = await this.$miniProgram.tradePay(
          merchantStore.platform,
          transactionData
        );
        this.$router.replace({
          name: "OrderSummary",
          query: { orderID: orderIDs[0] }
        });
      } else if (this.isBoost || this.isSenHeng) {
        trxStore.SET_BOOST_PAYING_FLAG(this.isBoost);
        this.$router.replace({
          name: "OrderSummary",
          query: { orderID: orderIDs[0] }
        });
        // this.monitorTransactionStatus(orderIDs[0]);
        window.location.href = transactionData;
      }
    } else if (paymentRedirectUrl) {
      this.$router.replace({
        name: "OrderSummary",
        query: { orderID: orderIDs[0] },
        params: { paymentRedirectUrl }
      });
    }
  }

  // async monitorTransactionStatus(orderId) {
  //   const order = await trxStore.getOrderById(orderId as string);
  //   if (order?.paymentStatus == "PENDING") {
  //     setTimeout(() => {
  //       this.monitorTransactionStatus(orderId);
  //     }, 1000);
  //   } else {
  //     this.$router.push({ name: "OrderSummary", query: { orderID: orderId } });
  //   }
  // }

  getItemPrice(amount: number, rate: number) {
    return menuStore.toFormattedTaxInclusive(amount, rate);
  }

  formatDate(date: string, format: string, add = 0) {
    if (date === "ASAP") return this.$t("Checkout.asap");
    return dayjs(date)
      .add(add, "m")
      .format(format);
  }

  isValidDeliveryTime(time: string): boolean {
    //once we have other ways of predicting the likelihood of rider availability, we enable this back with new logic
    // and include it in the display of slots with v-for computed property
    // Check if needs to block the delivery time

    // TODO: The following disables peak hour deliveyr time
    if (
      this.isBoost ||
      this.selectedDineType != "DELIVERY" ||
      this.isStoreUsingOwnDelivery
    ) {
      return true;
    }
    const deliveryTime = dayjs(time);
    const deliveryHour = deliveryTime.hour();

    return (
      !(deliveryHour >= 11 && deliveryHour < 14) &&
      !(deliveryHour >= 17 && deliveryHour < 20)
    );
  }

  onSelectOrderDayIndex(i: number) {
    this.selectedOrderDayIndex = i;
    this.selectedDeliveryTime = this.deliveryTime[i][0];
    this.selectedDineType === "DINEIN" && i === 0
      ? (this.selectedDeliveryTime = "ASAP")
      : null;
    cookies.set("selectedDeliveryTime", this.selectedDeliveryTime);
    cookies.set("selectedOrderDayIndex", this.selectedOrderDayIndex);
  }

  onSelectDeliveryTime(i: number, time: string) {
    this.selectedDeliveryTime = time;
    this.selectedDineType === "DINEIN" &&
    i === 0 &&
    this.selectedOrderDayIndex === 0
      ? (this.selectedDeliveryTime = "ASAP")
      : null;
    cookies.set("selectedDeliveryTime", this.selectedDeliveryTime);
  }

  async onSelectDineType(key: string) {
    this.checkFoodAvailability();
    this.selectedDineType = key;
    cookies.set("selectedDineType", key);
    this.isCalculatingDeliveryFee = false;

    // For Ramadan and Raya Usage
    // if (
    //   key === "DELIVERY" &&
    //   !(await cookies.getSessionStorage("isShowPopUpMessage")) &&
    //   !this.isMealPlan
    // ) {
    //   this.isShowPopUpMessage = false;
    //   cookies.setSessionStorage("isShowPopUpMessage", "YES");

    //   setTimeout(() => {
    //     this.isShowPopUpMessage = false;
    //   }, 10000);
    // }

    if (
      key === "DELIVERY" &&
      this.currentStore &&
      this.deliveryAmount === -1 &&
      this.subtotal > 0
    ) {
      this.calculateDeliveryFee();
    }
    if (!empty(this.promoCodeInput)) {
      this.debouncedApplyPromo();
    }
    if (key == "DINEIN") {
      this.onCutlerySwitch(false);
    }
  }
  onCutlerySwitch(checked: boolean) {
    this.isCutleryRequired = checked;
    cookies.set("isCutleryRequired", checked);
  }
  async loadDineTypes() {
    if (this.dineTypes.length <= 0) {
      const { type } = this.table;
      const dinetypes = type.split("/");

      if (!(await cookies.get("selectedDineType")))
        this.selectedDineType = dinetypes[0];

      dinetypes.forEach((each) => {
        let x: object[] = [];
        switch (each) {
          case "DRIVE_THRU":
            x = [{ key: "DRIVE_THRU", value: this.$t("Checkout.drive-thru") }];
            break;
          case "DELIVERY":
            x = [{ key: "DELIVERY", value: this.$t("Checkout.delivery") }];
            break;
          case "DINEIN":
            x = [{ key: "DINEIN", value: this.$t("Checkout.walkin") }];
            break;
          case "PICKUP":
            x = [{ key: "PICKUP", value: this.$t("Checkout.pickup") }];
            break;
        }
        this.dineTypes.push(...x);
      });
    }
    if (
      this.dineTypes.some((dt: any) => dt.key == "DELIVERY") &&
      this.isMealPlan
    ) {
      this.dineTypes = this.dineTypes.filter((dt: any) => dt.key == "DELIVERY");
      this.selectedDineType = "DELIVERY";
    }
  }
  clearCart() {
    cartStore.clearCart();
    const { id } = this.$route.params;
    if (id) {
      cookies.remove("isCutleryRequired");
      cookies.remove(`cart-${id}`);
    }
    this.removePromoCode();
  }

  clearLocalCart() {
    this.clearCart();
    this.localCart = [];
  }

  getItemFromCart(cartItemID: string): ICart | null {
    const cart = cartStore.cart;
    return cart.find(
      (each: ICart) => each.item?.id === cartItemID
    ) as ICart | null;
  }

  removeItemFromCartById(cartItemID: string) {
    cartStore.removeItemfromCartById(cartItemID);
    this.updateLocalCart();
    this.reapplyPromoCode(this.promoCodeInput);
  }

  removeItemsFromCartByBatch(cartItemIDs: string[]) {
    for (let i = 0; i < cartItemIDs.length; i++) {
      cartStore.removeItemfromCartById(cartItemIDs[i]);
    }
    this.updateLocalCart();
    this.reapplyPromoCode(this.promoCodeInput);
  }

  private guide() {
    this.driver = new Driver();
    this.driver.defineSteps([
      {
        element: "#d_swipelist",
        popover: {
          className: "",
          title: "Want to remove some item only?",
          description: "Swipe left to reveal the delete button.",
          position: "bottom"
        }
      }
    ]);
    this.driver.start();
  }

  getDeliveryTime(selectedDay, selectedTime) {
    if (selectedTime === "ASAP") return dayjs();
    if (!selectedDay || !selectedTime) return dayjs();
    return selectedDay
      .hour(dayjs(selectedTime).hour())
      .minute(dayjs(selectedTime).minute());
  }

  filterDeliveryTime() {
    const initialDeliveryTime = this.selectedDeliveryTime;
    const initialOrderDayIndex = this.selectedOrderDayIndex;
    // Check if the initially selected one is valid, if yes, then dont have set the time again in the bottom loop
    if (
      initialDeliveryTime != "" &&
      initialDeliveryTime != "ASAP" &&
      this.deliveryTime &&
      this.deliveryTime[initialOrderDayIndex]
    ) {
      const initiallySelectedTime = dayjs(initialDeliveryTime).format("HH:mm");
      for (let j = 0; j < this.deliveryTime[initialOrderDayIndex].length; j++) {
        if (
          dayjs(this.deliveryTime[initialOrderDayIndex][j]).format("HH:mm") ==
          initiallySelectedTime
        ) {
          return;
        }
      }
    }

    for (let i = 0; i < this.deliveryTime.length; i++) {
      if (this.deliveryTime[i].length > 0) {
        this.selectedDeliveryTime = this.deliveryTime[i][0];
        this.selectedOrderDayIndex = i;
        this.unsavedDeliveryTime = this.selectedDeliveryTime;
        cookies.set("selectedDeliveryTime", this.selectedDeliveryTime);
        cookies.set("selectedOrderDayIndex", this.selectedOrderDayIndex);
        break;
      }
    }
  }

  updateNow() {
    this.now = dayjs();
  }

  updateLocalCart() {
    this.localCart = JSON.parse(JSON.stringify(cartStore.cart));
  }

  openBottomSheet() {
    const bottomSheet: any = this.$refs.storeListBottomSheet;
    bottomSheet.open();
  }

  closeBottomSheet() {
    this.selectedDeliveryTime = this.unsavedDeliveryTime;
    cookies.set("selectedDeliveryTime", this.selectedDeliveryTime);
    this.selectedOrderDayIndex = this.unsavedOrderDayIndex;
    cookies.set("selectedOrderDayIndex", this.selectedOrderDayIndex);
    const bottomSheet: any = this.$refs.storeListBottomSheet;
    bottomSheet.close();
  }

  async openVoucherBottomSheet() {
    const bottomSheet: any = this.$refs.voucherBottomSheet;
    bottomSheet.open();
    if (this.localVouchers.length == 0) {
      this.isVoucherLoading = true;
      const items = await this.fetchUserVouchers();
      this.isVoucherLoading = false;
    }
  }

  closeVoucherBottomSheet() {
    const bottomSheet: any = this.$refs.voucherBottomSheet;
    bottomSheet.close();
  }

  useVoucher(v: IVoucher) {
    this.voucherGroupCodeApplied = v.group.id;

    this.setPromoDisplay(v);
    this.reapplyPromoCode(v.code);

    this.closeVoucherBottomSheet();
  }

  async setPromoDisplay(v: IVoucher) {
    this.appliedStaticPromoCodeForDisplay =
      v.group.codeType == "STATIC" ? v.group.staticCode : "";
    cookies.set(
      `promo-display-${await cookies.get("currentStore")}`,
      this.appliedStaticPromoCodeForDisplay
    );
  }

  isVoucherUsed(v: IVoucher) {
    return (
      v.status == "REDEEMED" || v.status == "APPLIED" || v.status == "REFUNDED"
    );
  }
  redirectToVoucherPage(v: IVoucher) {
    if (!isNestle) {
      const routeData = this.$router.resolve({
        path: `/voucher/${v.group.id}`
      });
      window.open(routeData.href, "_blank");
    } else {
      this.tncVoucherGroup = v.group;
    }
  }

  isVoucherExpired(v: IVoucher) {
    return (
      (v.group.expiryAt && dayjs().isAfter(dayjs(v.group.expiryAt))) ||
      (v.group.startAt && dayjs().isBefore(dayjs(v.group.startAt)))
    );
  }
  getVoucherDineType(v: IVoucher) {
    const dineTypes =
      v.group.allowedDineTypes?.map((e) => {
        switch (e) {
          case "DRIVE_THRU":
            return this.$t("Checkout.drive-thru").toString();
          case "DELIVERY":
            return this.$t("Checkout.delivery").toString();
          case "DINEIN":
            return this.$t("Checkout.walkin").toString();
          case "PICKUP":
            return this.$t("Checkout.pickup").toString();
        }
        return "";
      }) || [];

    return `${this.$t("Checkout.only-for", {
      dineTypes: dineTypes?.join(", ")
    })}`;
  }

  getVoucherValidity(v: IVoucher) {
    const format = "DD/MM/YYYY";
    if (v.group.startAt && v.group.expiryAt) {
      return this.$t("Checkout.valid-from-to", {
        from: dayjs(v.group.startAt).format(format),
        to: dayjs(v.group.expiryAt).format(format)
      });
    } else if (v.group.startAt) {
      return this.$t("Checkout.valid-from", {
        from: dayjs(v.group.startAt).format(format)
      });
    } else if (v.group.expiryAt) {
      return this.$t("Checkout.valid-to", {
        to: dayjs(v.group.expiryAt).format(format)
      });
    }

    // return this.$t("Checkout.valid");
    return "";
  }

  getVoucherDescription(v: IVoucher) {
    let description = "";
    if (
      mealPlanVoucherGroupIDSandbox.includes(v.group.id) ||
      mealPlanVoucherGroupID.includes(v.group.id)
    ) {
      description = "Free 5 Days Meal Plan";
    } else {
      switch (v.group.type) {
        case "CASH":
          description = this.$t("Checkout.discount-off", {
            amount: `RM${v.group.amount / 100}`
          }).toString();
          break;
        case "PERCENTAGE":
          description = this.$t("Checkout.discount-off", {
            amount: `${v.group.amount / 100}%`
          }).toString();
          break;
        default:
          return "";
      }

      if (v.group.minPurchase > 0) {
        description = `${description}, ${this.$t("Checkout.min-purchase", {
          amount: `RM${v.group.minPurchase / 100}`
        }).toString()}`;
      }

      if (v.group.maxCappedDiscount > 0) {
        description = `${description}, ${this.$t("Checkout.capped-at", {
          amount: `RM${v.group.maxCappedDiscount / 100}`
        }).toString()}`;
      }
    }

    return description;
  }

  openReserveTableBottomSheet() {
    const bottomSheet: any = this.$refs.reserveTableBottomSheet;
    if (!this.noReservationTimeSlot) {
      bottomSheet?.open();
    }
  }

  closeReserveTableBottomSheet() {
    const bottomSheet: any = this.$refs.reserveTableBottomSheet;
    bottomSheet?.close();
  }

  handleReserveOpen() {
    if (
      !this.selectedReserveDeliveryTime ||
      this.selectedReserveDeliveryTime == "ASAP"
    ) {
      this.selectedReserveDayIndex = 0;
      this.selectedReserveDeliveryTime = this.reservationTime[
        this.selectedReserveDayIndex
      ][0];
    }
  }

  async reserveTable() {
    this.isReserveLoading = true;
    if (!this.selectedReserveDeliveryTime)
      this.selectedReserveDeliveryTime = this.reservationTime[
        this.selectedReserveDayIndex
      ][0];
    const deliveryTime = this.getDeliveryTime(
      this.reservePreOrderDays[this.selectedReserveDayIndex],
      this.selectedReserveDeliveryTime
    ).toISOString();
    let reservation = await tableReservationStore.reserveTable({
      storeID: this.currentStore ? this.currentStore.id : "",
      startAt: deliveryTime,
      noOfPax: this.reservePax,
      remark: this.reserveRemark
    });

    if (
      this.selectedDineType == "DINEIN" &&
      this.selectedReserveDeliveryTime == "ASAP"
    ) {
      this.dineInOrReserve = "DINEIN";
    } else if (this.selectedDineType == "DINEIN") {
      this.dineInOrReserve = "RESERVATION";
      if (
        this.showBookingModal == false &&
        this.hasShownBookingModal == false
      ) {
        this.showBookingModal = true;
        this.hasShownBookingModal = true;
      }
    } else {
      this.dineInOrReserve = "OTHERS";
    }

    this.isReserveLoading = false;
    this.closeReserveTableBottomSheet();
  }

  onReserveDayChange(e) {
    const { $swiper } = this.$refs.reserveDaySwiper as any;
    this.selectedReserveDayIndex = $swiper.realIndex;
    this.selectedReserveDeliveryTime = this.reservationTime[
      $swiper.realIndex
    ][0];
  }

  onDayChange(e) {
    const { $swiper } = this.$refs.daySwiper as any;
    this.unsavedOrderDayIndex = $swiper.realIndex;
    this.unsavedDeliveryTime = this.deliveryTime[$swiper.realIndex][0];
  }

  onReserveTimeChange(e) {
    const { $swiper } = this.$refs.reserveTimeSwiper as any;
    this.unsavedReserveTimeIndex = $swiper.realIndex;
    this.selectedReserveDeliveryTime = this.reservationTime[
      this.selectedReserveDayIndex
    ][$swiper.realIndex];
  }

  onTimeChange(e) {
    const { $swiper } = this.$refs.timeSwiper as any;
    this.unsavedDeliveryTimeIndex = $swiper.realIndex;
    this.unsavedDeliveryTime = this.deliveryTime[this.unsavedOrderDayIndex][
      $swiper.realIndex
    ];
    // if (this.selectedDineType == "DELIVERY")
    //   this.unsavedDeliveryTime = this.deliveryTimeForDelivery[this.unsavedOrderDayIndex][$swiper.realIndex];
  }

  removeReservation(e) {
    this.selectedReserveDeliveryTime = "ASAP";
    this.unsavedOrderDayIndex = 0;
    this.dineInOrReserve = "DINEIN";
    e.stopPropagation();
  }

  async cFa(dineType = this.selectedDineType) {
    this.campaignDiscountAmount = 0;
    let customPreOrderDays =
      this.dineInOrReserve == "RESERVATION"
        ? this.reservePreOrderDays
        : this.preOrderDays;
    if (
      this.selectedDeliveryTime &&
      !empty(customPreOrderDays) &&
      this.$store.state.cartStore.cart
    ) {
      let deliveryTime = this.getDeliveryTime(
        customPreOrderDays[this.selectedOrderDayIndex],
        this.selectedDeliveryTime
      ).toISOString();
      this.availability = await cartStore.checkFoodAvailability({
        cart: this.$store.state.cartStore.cart,
        deliveryTime: deliveryTime,
        dineType: dineType,
        dineTime: this.mealPlanItem?.selectedDineTime
      });

      if (this.availability?.discounts?.length > 0) {
        this.campaignDiscountAmount = this.availability.discounts.reduce(
          (grandTotal, item) => {
            return (
              grandTotal +
              item.discounts.reduce((total, food) => {
                return total + food.discountAmountPerUnit * food.quantity;
              }, 0)
            );
          },
          0
        );
      }

      this.isExceedBikeSize = this.availability?.isExceedBikeSize as boolean;
      if (
        this.availability?.unavailableFoods &&
        this.availability.unavailableFoods.length > 0
      ) {
        let foodNames = [] as string[];
        let cartItemIDs = [] as string[];
        for (let i = 0; i < this.availability.unavailableFoods.length; i++) {
          let cartItem = this.getItemFromCart(
            this.availability.unavailableFoods[i].id
          );
          if (cartItem) {
            foodNames.push(cartItem.item.name);
            cartItemIDs.push(cartItem.cartItemID);
          }
        }

        if (
          this.isMealPlan &&
          this.currentStore &&
          this.availability?.unavailableItemIDs
        ) {
          let unavailableDays = this.findMealPlanUnavailableDays(
            this.availability?.unavailableItemIDs,
            cartItemIDs
          );
          this.$ionic.toastController
            .create({
              header:
                unavailableDays.join(", ") +
                " of " +
                foodNames.join(", ") +
                " is unavailable at the moment, please select a different date",
              position: "top",
              color: "danger",
              duration: 5000,
              buttons: [
                {
                  icon: "close",
                  role: "cancel"
                }
              ]
            })
            .then((x) => x.present());
          this.$router.push({
            name: "FoodView",
            params: {
              id: this.currentStore.id,
              foodId: this.availability.unavailableFoods[0].id
            },
            query: { cartId: cartItemIDs[0] }
          });
        } else {
          this.removeItemsFromCartByBatch(cartItemIDs);

          this.$ionic.toastController
            .create({
              header:
                foodNames.join(", ") +
                " is unavailable at the moment and is removed from cart",
              position: "top",
              color: "primary",
              duration: 5000,
              buttons: [
                {
                  icon: "close",
                  role: "cancel"
                }
              ]
            })
            .then((x) => x.present());
        }
      }

      this.fetchUserVouchers(this.promoCodeInput == "");
    }

    if (!isBoost()) {
      const isEntitledForSpinAndWin = this.checkIsEntitledForSpinAndWin(false);
      // Show spin and win banner
      if (isEntitledForSpinAndWin) this.isShowSpinAndWinBanner = true;
    }
  }
  public checkFoodAvailability = debounce(this.cFa, 300);

  findMealPlanUnavailableDays(
    unavailableItemIDs,
    cartItemIDs: string[]
  ): string[] {
    // cartItemIDs will only contain one element for meal plans (only one type of meal plan per order allowed)
    const cartMealPlanID = cartItemIDs[0];
    const mealPlanInCart = cartStore.cart.find(
      (cartItem) => cartItem.cartItemID == cartMealPlanID
    );
    return unavailableItemIDs
      .filter((item) => item.type == "MEAL_PLAN_ITEM")
      .reduce((acc, currItem) => {
        // find which day meal plan is unavailable
        acc.push(
          mealPlanInCart?.selectedSides.find((day) => day.id == currItem.id)
            ?.name
        );
        return acc;
      }, []);
  }

  get recommendations() {
    return foodStore.recommendations;
  }

  async redirectToSpinAndWin() {
    const url = await userStore.enterSpinAndWin();
    if (url) {
      // Set to cookie
      if (this.currentStore) {
        const cookieValue: ICookie = {
          value: "true",
          expiry: dayjs()
            .add(15, "minute")
            .toISOString()
        };
        const item = JSON.stringify(cookieValue);
        cookies.set(
          `spin-and-win-${this.currentStore.id}`,
          JSON.stringify(cookieValue)
        );
      }

      window.location.href = url;
    }
  }

  @Watch("selectedDineType")
  onSelectedDineTypechanged() {
    if (this.selectedDineType != "DINEIN") {
      this.dineInOrReserve = "OTHERS";
    } else if (
      this.selectedDineType == "DINEIN" &&
      this.selectedReserveDeliveryTime == "ASAP"
    ) {
      this.dineInOrReserve = "DINEIN";
    } else {
      this.dineInOrReserve = "RESERVATION";
    }
    this.filterDeliveryTime();

    if (this.promoCodeInput) {
      this.reapplyPromoCode(this.promoCodeInput);
    }
  }

  // @Watch("selectedDeliveryTime")
  // onDeliveryTimechanged(deliveryTime) {
  //   console.log(''
  //     "selectedDeliveryTime",
  //     this.getDeliveryTime(this.preOrderDays[this.selectedOrderDayIndex], deliveryTime).toISOString()
  //   );
  // }

  @Watch("excludedDeliveryOptions")
  onExcludedDeliveryOptionsChanged() {
    if (this.excludedDeliveryOptions.includes(this.selectedDeliveryOption)) {
      this.selectedDeliveryOption = this.storeDeliveryOptions[0];
      this.calculateDeliveryFee();
    }
  }

  @Watch("currentStore")
  onCurrentStoreChanged(newVal) {
    if (newVal) {
      const { currentOpeningHour, currentClosingHour } = newVal;
      this.updateNow();
      if (this.isTodayOpen) {
        this.updateNowTimer = setInterval(this.updateNow, 60 * 1000);
        this.todayStartAt = dayjs(currentOpeningHour, "HH:mm");
        this.todayEndAt = dayjs(currentClosingHour, "HH:mm");
        if (dayjs(this.todayStartAt).isAfter(dayjs(this.todayEndAt))) {
          this.todayEndAt = dayjs(this.todayEndAt).add(1, "day");
        }

        this.durationTimeout = dayjs.duration(this.now.diff(this.todayStartAt));
      }
      this.filterDeliveryTime();

      this.reapplyPromoCode(this.promoCodeInput);
      this.selectedDeliveryOption =
        newVal.deliveryTypes?.filter((item) => item?.isDefault)[0]?.type ||
        "MOTORBIKE";
    }
  }

  @Watch("selectedDeliveryTime")
  onDeliveryTimeUpdated(newValue) {
    if (this.selectedDeliveryTime) {
      this.checkFoodAvailability();
      this.calculateDeliveryFee();
    }
  }

  @Watch("currentStore")
  @Watch("profile")
  onUserProfileChanged(newVal) {
    if (
      newVal &&
      this.currentStore &&
      this.selectedDineType === "DELIVERY" &&
      this.deliveryAmount === -1 &&
      this.subtotal > 0
    ) {
      this.calculateDeliveryFee();
    }
    if (newVal?.vehicles) {
      this.vehicleNoInput =
        newVal.vehicles[newVal.primaryVehicle as number]?.plate || "";
      this.vehicleDescInput =
        newVal.vehicles[newVal.primaryVehicle as number]?.model || "";
    }
  }
  @Watch("table")
  onTableChanged() {
    this.loadDineTypes();
  }

  @Watch("cart")
  onCartChanged() {
    this.updateLocalCart();
  }

  @Watch("cartStoreTotal")
  onCartItemsChanged() {
    if (!this.isInactive && this.selectedDeliveryTime && this.subtotal > 0) {
      this.checkFoodAvailability();
    }
  }

  @Watch("subtotal")
  onSubtotalChanged(newVal) {
    if (
      this.currentStore &&
      this.selectedDineType === "DELIVERY" &&
      this.deliveryAmount === -1 &&
      this.subtotal > 0
    ) {
      this.calculateDeliveryFee();
    }
  }

  created() {
    dayjs.extend(customParseFormat);
    dayjs.extend(isBetween);
    dayjs.extend(isSameOrAfter);
    dayjs.extend(isSameOrBefore);
    dayjs.extend(relativeTime);
    dayjs.extend(updateLocale);
    dayjs.extend(weekday);
    dayjs.extend(calendar);
    dayjs.extend(duration);
    dayjs.extend(utc);
  }
  async activated() {
    this.isInactive = false;

    const cachedCart = JSON.parse(
      (await cookies.get(`cart-${this.$route.params.id}`)) || "null"
    );
    if (cachedCart) {
      cartStore.loadCart(cachedCart);
    }

    let locale = "en";
    if (this.$i18n.locale == "cn") {
      locale = "zh-cn";
      dayjs.locale(locale);
    }

    dayjs.updateLocale(locale, {
      calendar: {
        lastDay: this.$t("Checkout.yesterday"),
        sameDay: this.$t("Checkout.today"),
        nextDay: this.$t("Checkout.tomorrow"),
        lastWeek:
          this.$t("Checkout.last") +
          " dddd " +
          this.$t("Checkout.at") +
          " h:mm A",
        nextWeek:
          this.$t("Checkout.on-this-coming") +
          " dddd " +
          this.$t("Checkout.at") +
          " h:mm A",
        sameElse:
          this.$t("Checkout.on-next-coming") +
          " dddd " +
          this.$t("Checkout.at") +
          " h:mm A"
      }
    });

    setTimeout(() => {
      const deliveryType = this.$refs.deliveryType as HTMLDivElement;
      if (deliveryType) {
        deliveryType.style.top = this.isMiniProgram ? "0rem" : "4rem";
      }
    }, 1500);

    this.loadDineTypes();

    this.tableNoInput =
      (await cookies.getSessionStorage(
        `table-${merchantStore.currentStore?.id}`
      )) || "";

    try {
      this.deliveryLocation = JSON.parse(
        (await cookies.get("delivery:location")) || "{}"
      );
    } catch (e) {
      console.error(e);
    }

    this.updateNow();

    this.updateLocalCart();

    this.vehicleNoInput = (await cookies.get("vehicleNo")) || "";

    this.vehicleDescInput = (await cookies.get("vehicleDescription")) || "";

    this.selectedDineType =
      (await cookies.get("selectedDineType")) || this.selectedDineType;

    if (this.isTableReserved) this.selectedDineType = "DINEIN";
    if (
      !this.dineTypes?.find(
        (each) => (each as any).key === this.selectedDineType
      ) &&
      this.dineTypes?.length > 0
    ) {
      this.onSelectDineType((this.dineTypes[0] as any).key);
    } else {
      this.onSelectDineType(this.selectedDineType);
    }

    try {
      const dL = await cookies.get("delivery:location");
      this.deliveryLocation = JSON.parse(dL || "{}");
    } catch (e) {
      console.error(e);
    }

    this.selectedDeliveryOption =
      this.currentStore?.deliveryTypes?.filter((item) => item?.isDefault)[0]
        ?.type || "MOTORBIKE";

    if (this.currentStore) {
      if (this.selectedDineType === "DELIVERY" && this.subtotal > 0) {
        this.calculateDeliveryFee();
      }

      this.setCheckoutDetail();
      this.filterDeliveryTime();
      if (this.isTodayOpen) {
        this.updateNowTimer = setInterval(this.updateNow, 60 * 1000);
        this.todayStartAt = dayjs(this.currentOpeningHour, "HH:mm");
        this.todayEndAt = dayjs(this.currentClosingHour, "HH:mm");

        if (dayjs(this.todayStartAt).isAfter(dayjs(this.todayEndAt))) {
          this.todayEndAt = dayjs(this.todayEndAt).add(1, "day");
        }
        this.durationTimeout = dayjs.duration(this.now.diff(this.todayStartAt));
      }
    }

    if (this.profile) {
      const { vehicles } = this.profile;
      if (vehicles) {
        this.vehicleNoInput =
          this.profile?.vehicles[this.profile?.primaryVehicle as number]
            ?.plate || "";
        this.vehicleDescInput =
          this.profile?.vehicles[this.profile?.primaryVehicle as number]
            ?.model || "";
      }
    }
    if (this.pickupOptions.length > 0) {
      this.selectedPickupOption = this.pickupOptions[0];
    }
    if (this.driveThruOptions.length > 0) {
      this.selectedDriveThruOption = this.driveThruOptions[0];
    }

    // Try to apply the promo code if there is
    const appliedPromoCode =
      (await cookies.get(`promo-${await cookies.get("currentStore")}`)) || "";
    const appliedStaticPromoCodeForDisplay =
      (await cookies.get(
        `promo-display-${await cookies.get("currentStore")}`
      )) || "";
    if (appliedPromoCode.trim().length > 0 && this.subtotal > 0) {
      this.appliedStaticPromoCodeForDisplay = appliedStaticPromoCodeForDisplay;
      this.reapplyPromoCode(appliedPromoCode.trim());
    } else {
      this.removePromoCode();
    }

    const isCR = await cookies.get("isCutleryRequired");
    this.isCutleryRequired = JSON.parse(isCR || "false");
    this.isShowShop5Banner =
      userStore.isNewUser &&
      !this.isBoost &&
      ((await cookies.get("isShownShop5Banner")) || "") != "true"; // Dont show to non-login user or boost mini program user
    this.isShowTapauBanner =
      userStore.isNewUser &&
      !this.isBoost &&
      ((await cookies.get("isShownTapauBanner")) || "") != "true";

    // if (this.isOrderPossible) {
    //   cookies.setSessionStorage(
    //     "deliveryLeadTime",
    //     dayjs(this.getDeliveryTime(this.preOrderDays[this.selectedOrderDayIndex], this.selectedDeliveryTime))
    //       .diff(dayjs().utc(), "second")
    //       .toString()
    //   );
    // }
    //postPone recommendation requests to prioritize other cart logic
    setTimeout(async () => {
      foodStore.getFoodRecommendations({
        storeId: this.$route.params.id,
        isNestleFoodOnly: false
      }); // get all recommended foods
      if (!isBoost()) {
        this.nestleProducts = await foodStore.getFoodRecommendations({
          storeId: this.$route.params.id,
          isNestleFoodOnly: true
        }); // get nestle products
      }
    }, 1000);

    if (
      this.selectedDineType == "DINEIN" &&
      empty(tableReservationStore.tableReservation?.startAt)
    ) {
      this.dineInOrReserve = "DINEIN";
      if (empty(this.selectedReserveDeliveryTime)) {
        this.selectedReserveDeliveryTime = "ASAP";
      }
    } else if (
      this.selectedDineType == "DINEIN" &&
      !empty(tableReservationStore.tableReservation?.startAt)
    ) {
      this.dineInOrReserve = "RESERVATION";
    } else {
      this.dineInOrReserve = "OTHERS";
      if (empty(this.selectedReserveDeliveryTime)) {
        this.selectedReserveDeliveryTime = "ASAP";
      }
    }
    // if (!isBoost()) {
    //   const hasNestleItem = this.checkIsEntitledForSpinAndWin(true);
    //   // Show banner to buy more nestle products
    //   if (!hasNestleItem && this.nestleProducts?.length > 0) {
    //     this.isShowBuyNestleProductsBanner = true;
    //     setTimeout(() => {
    //       this.isShowBuyNestleProductsBanner = false;
    //     }, 15000);
    //   }
    // }
  }

  get locale() {
    return this.$i18n.locale;
  }
  deactivated() {
    this.isInactive = true;
    if (this.isTodayOpen) clearInterval(this.updateNowTimer);
    this.subscription?.unsubscribe();
    // const deliveryType = this.$refs.deliveryType as HTMLDivElement;
    // if (deliveryType) {
    //   deliveryType.style.top = '0rem'
    // }
    const bottomSheet: any = this.$refs.storeListBottomSheet;
    bottomSheet?.close();

    const voucherBottomSheet: any = this.$refs.voucherBottomSheet;
    voucherBottomSheet?.close();
  }
}
